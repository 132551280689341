/* eslint-disable comma-spacing */
import React from "react";

import Head from "next/head";

import type { DashboardLayoutProps } from "@/app/core/layouts/dashboard/DashboardLayout";
import { DashboardLayout } from "@/app/core/layouts/dashboard/DashboardLayout";
import type { FCWithChildren, LayoutComponent } from "@/app/core/types/tsx";

// eslint-disable-next-line @typescript-eslint/ban-types
type GetProps<L> = L extends LayoutComponent<infer P> ? P : {};

type PageProps<L extends LayoutComponent = LayoutComponent<DashboardLayoutProps>> =
  FCWithChildren &
    GetProps<L> & {
      title?: string;
      layout?: L;
    };

export const Page = <L extends LayoutComponent = LayoutComponent<DashboardLayoutProps>>({
  title,
  children,
  layout,
  ...layoutProps
}: PageProps<L>) => {
  return (
    <>
      <Head>
        <title>{title ? `${title} | Oblido` : "Oblido"}</title>
      </Head>
      {(layout || DashboardLayout)(children, layoutProps ?? {})}
    </>
  );
};
