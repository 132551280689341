import React from "react";

import { useQuery } from "@tanstack/react-query";

import { Box } from "@/app/core/ui/components/Box";

interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  const { data: versionData } = useQuery(["version"], () =>
    fetch("/api/version", { method: "get" }).then(
      (r) => r.json() as Partial<{ buildId: string; version: string }>
    )
  );

  return (
    <Box tw="text-center text-gray-600 pt-6 pb-2 text-md">
      <span tw="font-bold">Oblido</span>
      {versionData?.version !== undefined && <span> v{versionData.version}</span>}
    </Box>
  );
};
