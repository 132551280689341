import React from "react";

import { Popover } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { Bug, ChevronDown, LifeBuoy, LogOut, Settings } from "lucide-react";
import Skeleton from "react-loading-skeleton";

import { useAuth } from "@/app/auth/hooks/useAuth";
import { Box } from "@/app/core/ui/components/Box";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";
import { useUser } from "@/app/user/hooks/useUser";

interface UserMenuProps {}

export const UserMenu: React.FC<UserMenuProps> = () => {
  const { data: user } = useUser();
  const { logout } = useAuth();

  return (
    <Popover tw="relative z-20">
      {({ open }) => (
        <>
          <Popover.Button tw="flex items-center gap-0.75 outline-none">
            <Flex
              center
              tw="w-3.5 h-3.5 bg-gray-300 rounded-full text-sm font-bold
              text-gray-800 flex-shrink-0 overflow-hidden [line-height: 0]"
            >
              {user ? (
                `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
              ) : (
                <Skeleton tw="w-3.5 h-3.5" />
              )}
            </Flex>

            <ChevronDown size="16" tw="hidden md:block" />
          </Popover.Button>

          <AnimatePresence>
            {open && (
              <Popover.Panel
                as={motion.div}
                static
                tw="bg-white rounded-lg shadow-lg border border-gray-300
                  p-0.5 mt-1 z-10 absolute right-0 top-full w-[22rem]"
                layout
                initial={{ opacity: 0, y: -10, scale: 1 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: -10, scale: 1 }}
                transition={{
                  type: "spring",
                  bounce: 0.4,
                  duration: 0.4,
                }}
              >
                <button tw="w-full flex items-center px-1 py-0.75 gap-1.25 hover:bg-gray-100 rounded-lg">
                  <Settings size="17" tw="text-gray-600" />
                  <Text>Preferencias</Text>
                </button>

                <button tw="w-full flex items-center px-1 py-0.75 gap-1.25 hover:bg-gray-100 rounded-lg">
                  <LifeBuoy size="17" tw="text-gray-600" />
                  <Text>Ayuda</Text>
                </button>

                <button tw="w-full flex items-center px-1 py-0.75 gap-1.25 hover:bg-gray-100 rounded-lg">
                  <Bug size="17" tw="text-gray-600" />
                  <Text>Informar de un error</Text>
                </button>

                <Box tw="w-full border-t border-gray-200 my-1" />

                <button
                  onClick={logout}
                  tw="w-full flex items-center px-1 py-0.75 gap-1.25 hover:bg-gray-100 rounded-lg"
                >
                  <LogOut size="17" tw="text-gray-600" />
                  <Text>Salir</Text>
                </button>
              </Popover.Panel>
            )}
          </AnimatePresence>
        </>
      )}
    </Popover>
  );
};
