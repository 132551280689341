import React from "react";

import * as icons from "lucide-react";
import Link from "next/link";
import tw from "twin.macro";

import { Text } from "@/app/core/ui/components/Text";
import type { MenuItemDef, MenuItemWithChildren } from "@obd/common";

interface MenuItemMobileProps {
  item: MenuItemDef;
  isActive: boolean;
}

export const MenuItemMobile: React.FC<MenuItemMobileProps> = ({ item, isActive }) => {
  const Icon = icons[item.icon as keyof typeof icons] as icons.LucideIcon;

  return (
    <Link
      key={(item.path ?? "") + item.name}
      href={item.path ?? (item as MenuItemWithChildren).children?.[0].path}
      tw="flex items-center flex-1 flex-col gap-[0.25rem] text-gray-600 pt-1.25 relative"
      css={{
        ...(isActive &&
          tw`text-gray-900 after:([content: ''] absolute top-0 left-1/2 -translate-x-1/2 h-0.25 w-2/3 bg-black rounded-b)`),
      }}
    >
      {Icon && React.cloneElement(<Icon />, { size: 23, strokeWidth: 1.5 })}
      <Text tw="text-[0.9rem]">{item.name}</Text>
    </Link>
  );
};
