import React from "react";

import { Footer } from "@/app/core/layouts/dashboard/components/Footer";
import { Menu } from "@/app/core/layouts/dashboard/components/Menu";
import type { LayoutComponent } from "@/app/core/types/tsx";
import { Flex } from "@/app/core/ui/components/Flex";

export interface DashboardLayoutProps {
  noContainer?: boolean;
}

export const DashboardLayout: LayoutComponent<DashboardLayoutProps> = (page, props) => {
  return (
    <Flex tw="flex-col h-full">
      <Menu />
      <Flex tw="flex-col bg-gray-100 flex-grow pb-4 md:pb-0">
        <Flex
          className={props.noContainer ? undefined : "container"}
          tw="flex-grow pt-2 flex-col"
        >
          {page}
        </Flex>
        <Footer />
      </Flex>
    </Flex>
  );
};
