import React, { useMemo } from "react";

import tw from "twin.macro";

interface AbsoluteProps {
  align?:
    | "centerRight"
    | "vertical"
    | "centerLeft"
    | "center"
    | "topRight"
    | "topLeft"
    | "bottomCenter";
  children: JSX.Element;
}

export const Absolute: React.FC<AbsoluteProps> = ({ children, align }) => {
  const style = useMemo(() => {
    switch (align) {
      case "centerRight":
        return tw`absolute transform top-1/2 right-0 -translate-y-1/2`;
      case "vertical":
        return tw`absolute transform top-1/2 -translate-y-1/2`;
      case "centerLeft":
        return tw`absolute transform left-0 top-1/2 -translate-y-1/2`;
      case "center":
        return tw`absolute transform left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2`;
      case "topRight":
        return tw`absolute right-0 top-0`;
      case "topLeft":
        return tw`absolute left-0 top-0`;
      case "bottomCenter":
        return tw`absolute transform left-1/2 bottom-0 -translate-x-1/2`;
      default:
        return tw`absolute transform`;
    }
  }, [align]);

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          style: { ...style },
        })
      )}
    </>
  );
};
