import React from "react";

import { AlertTriangle } from "lucide-react";
import Link from "next/link";
import { useRouter } from "next/router";

import { useTokens } from "@/app/auth/store/useTokens";
import { useApiQuery } from "@/app/core/http/hooks/useApiQuery";
import { routes } from "@/app/core/http/routes";
import { MenuItem } from "@/app/core/layouts/dashboard/components/MenuItem";
import { MenuItemMobile } from "@/app/core/layouts/dashboard/components/MenuItemMobile";
import { UserMenu } from "@/app/core/layouts/dashboard/components/UserMenu";
import { Absolute } from "@/app/core/ui/components/Absolute";
import { Flex } from "@/app/core/ui/components/Flex";
import { OblidoLogo } from "@/app/core/ui/components/OblidoLogo";
import { Text } from "@/app/core/ui/components/Text";
import { useUser } from "@/app/user/hooks/useUser";
import type { MenuItemWithChildren } from "@obd/common";

interface MenuProps extends React.ComponentProps<typeof Flex> {}

export const Menu: React.FC<MenuProps> = ({ ...props }) => {
  const { pathname } = useRouter();
  const { data: config, isLoading } = useApiQuery("getConfig", {
    staleTime: Infinity,
    keepPreviousData: true,
  });

  const activeItemIdx = config?.menu.findIndex((item) => {
    const pathNoSlash = item.path?.slice(1);
    const basePathName = pathname.slice(1).split("/")[0];

    if (
      pathname === item.path ||
      ("children" in item && item.children.some((c) => c.path === pathname)) ||
      (basePathName && pathNoSlash && pathNoSlash === basePathName)
    ) {
      return true;
    }

    return false;
  });

  const isImpersonating = useTokens((s) => s.impersonation);
  const user = useUser();

  return (
    <Flex tw="w-full flex-col z-50" {...props}>
      {isImpersonating && (
        <Flex center tw="w-full h-2 bg-[#ffc560] text-[#664100] text-xs font-medium">
          Impersonando a {user.data?.email}
        </Flex>
      )}
      <Flex tw="bg-white h-6 border-b border-gray-300">
        <Flex className="container" tw="justify-between relative">
          <Link href={routes.home} tw="flex items-center">
            <OblidoLogo icon tw="w-2.5" />
          </Link>

          <Absolute align="center">
            <Flex tw="gap-1 hidden md:flex">
              {isLoading ? null : (
                <>
                  {config?.menu.length ? (
                    config.menu.map((item, i) => (
                      <MenuItem
                        key={item.name + i}
                        item={item}
                        isActive={i === activeItemIdx}
                      />
                    ))
                  ) : (
                    <Text size="md" tw="flex items-center gap-0.75 text-red-600">
                      <AlertTriangle tw="text-red-600" size="14" /> No tienes un rol
                      asignado
                    </Text>
                  )}
                </>
              )}
            </Flex>
          </Absolute>

          <Flex tw="gap-3 items-center">
            <UserMenu />
          </Flex>
        </Flex>
      </Flex>

      {activeItemIdx === undefined ||
      !config?.menu?.[activeItemIdx] ||
      !("children" in config.menu[activeItemIdx]) ? null : (
        <Flex tw="bg-white border-b border-gray-300">
          <Flex className="container" tw="gap-2.5 items-center">
            {(
              config.menu[activeItemIdx] as Omit<MenuItemWithChildren, "icon">
            ).children.map((child, i) => (
              <MenuItem
                key={child.path}
                item={child}
                isFirst={i === 0}
                isChildren
                isActive={pathname === child.path}
              />
            ))}
          </Flex>
        </Flex>
      )}

      <Flex tw="h-6 bg-white fixed bottom-0 left-0 w-full border-t border-gray-300 justify-between [box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)] md:hidden">
        {config?.menu.map((item, i) => (
          <MenuItemMobile
            key={item.name + i}
            item={item}
            isActive={i === activeItemIdx}
          />
        ))}
      </Flex>
    </Flex>
  );
};
