import React from "react";

import Link from "next/link";
import tw from "twin.macro";

import { Text } from "@/app/core/ui/components/Text";
import type { MenuItemDef, MenuItemWithChildren } from "@obd/common";

interface MenuItemProps {
  item: Omit<MenuItemDef, "icon">;
  isChildren?: boolean;
  isActive: boolean;
  isFirst?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  item,
  isChildren = false,
  isFirst = false,
  isActive,
}) => {
  if (isChildren) {
    return (
      <Link
        href={item.path!}
        tw="border-b-2 border-transparent h-full flex items-center px-1 py-0.75
        text-md transition-colors hover:(border-gray-300 text-gray-800)"
        css={{
          ...(isFirst ? tw`-mx-1` : {}),
          ...(isActive
            ? tw`border-gray-700 font-bold text-black`
            : tw`font-normal text-gray-700`),
        }}
      >
        {item.name}
      </Link>
    );
  }

  return (
    <Link
      href={item.path ?? (item as MenuItemWithChildren).children?.[0].path}
      tw="flex px-1.25 py-0.25 rounded-lg"
      css={isActive ? tw`bg-gray-200` : tw`hover:bg-gray-150`}
    >
      <Text weight="medium" size="md">
        {item.name}
      </Text>
    </Link>
  );
};
